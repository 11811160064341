<template>
  <div>
    <AuthHeader />
    <main
      class="min-h-[70vh] mt-20 px-4 lg:px-30 bg-gray-100 text-gray-800 p-6"
    >
      <div class="max-w-4xl mx-auto bg-white p-8 rounded-lg shadow-md">
        <h1 class="text-2xl font-bold mb-4">
          <span class="capitalize">{{ PROJECT_NAME }}</span> OneID Authenticator
          Privacy Policy
        </h1>
        <p class="text-sm text-gray-600 mb-6">Effective from January 2024</p>

        <h2 class="text-xl font-semibold mb-3">Introduction</h2>
        <p class="mb-4">
          OneID Synergy Impex Ltd is committed to protecting the privacy and
          security of your personal information. This privacy policy outlines
          our practices regarding the collection, use, and disclosure of
          personal information through our website and online identity
          verification platform (collectively, the "Platform"), in compliance
          with the Nigeria Data Protection Regulation (NDPR) 2019, Nigeria Data
          Protection Act (NDPA) 2023 and other relevant privacy laws in Nigeria.
        </p>

        <h2 class="text-xl font-semibold mb-3">2. Information We Collect</h2>
        <p class="mb-4">
          We collect information that identifies, relates to, describes, or is
          capable of being associated with you ("Personal Information"),
          including but not limited to:
        </p>
        <ul class="list-disc list-inside mb-4">
          <li>Names, email addresses, phone numbers, and dates of birth.</li>
          <li>Biometric information for identity verification purposes.</li>
          <li>IP addresses, browser types, and access times.</li>
        </ul>

        <h2 class="text-xl font-semibold mb-3">
          3. How We Use Your Information
        </h2>
        <p class="mb-4">
          Your Personal Information is used for the following purposes:
        </p>
        <ul class="list-disc list-inside mb-4">
          <li>To provide and improve our identity verification services.</li>
          <li>
            To comply with applicable legal requirements and regulatory
            obligations.
          </li>
          <li>For customer support and to respond to your inquiries.</li>
          <li>For internal research and development purposes.</li>
        </ul>

        <h2 class="text-xl font-semibold mb-3">
          4. Sharing and Disclosure of Personal Information
        </h2>

        <p class="mb-4">
          We do not sell your Personal Information, nor do we share your
          information with other third parties. The third parties we may share
          your personal information with are the law enforcement agencies only
          when it is necessary to comply with legal obligations.
        </p>

        <h2 class="text-xl font-semibold mb-3">5. Data Security</h2>
        <p class="mb-4">
          We implement reasonable security measures to protect your Personal
          Information from unauthorized access, disclosure, alteration, or
          destruction. However, no internet-based platform can be 100% secure,
          and we cannot guarantee the security of your Personal Information.
        </p>

        <h2 class="text-xl font-medium mb-2">6. Data Retention</h2>
        <p class="mb-4">
          You may permanently and irreversibly delete your data at any time. We
          do not retain your face data; we only store a template of your face
          data for as long as is required for reference purposes only and such
          data can be deleted immediately upon your request. This data is stored
          encrypted and may only be accessed by authorized account
          administrators.
        </p>

        <h2 class="text-xl font-semibold mb-3">7. Your Rights</h2>
        <p class="mb-4">
          Under the NDPR, NDPA and other applicable laws, you have rights
          concerning your Personal Information, including the right to access,
          correct, delete, or restrict the use of your Personal Information. To
          exercise these rights, please contact us using the information below.
        </p>

        <h2 class="text-xl font-semibold mb-3">
          8. Changes to Our Privacy Policy
        </h2>
        <p class="mb-4">
          We may update this privacy policy from time to time. The effective
          date at the top of this policy will be updated to reflect any changes.
          We encourage you to review our privacy policy periodically.
        </p>

        <h2 class="text-xl font-semibold mb-3">9. Contact Us</h2>
        <p class="mb-4">
          If you have any questions about this privacy policy or our data
          practices, please contact us at:
        </p>
        <ul class="list-disc list-inside mb-4">
          <li>Email: info@oneid.ng</li>
          <li>Phone: 09025579505</li>
        </ul>
      </div>
    </main>
    <AuthFooter />
  </div>
</template>

<script>
import AuthHeader from "@/components/auth/Header.vue";
import AuthFooter from "@/components/auth/Footer.vue";

export default {
  name: "Signup",

  components: {
    AuthHeader,
    AuthFooter,
  },

  data() {
    return {
      form: {
        verificationCode: null,
        salaryAccountNumber: null,
        email: null,
        phoneNumber: null,
      },
      regging: false,
    };
  },

  computed: {
    emailReady() {
      return (
        (this.form.email &&
          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
            this.form.email
          )) ||
        !this.form.email
      );
    },
    phoneReady() {
      return (
        (this.form.phoneNumber &&
          /^(\+[\d]{1,13}|0[\d]{1,11})$/.test(this.form.phoneNumber)) ||
        !this.form.phoneNumber
      );
    },
    formReady() {
      return (
        this.form.verificationCode &&
        this.form.verificationCode.length >= 5 &&
        this.form.salaryAccountNumber &&
        this.form.salaryAccountNumber.length === 10 &&
        this.emailReady &&
        this.phoneReady
      );
    },
  },

  methods: {
    async register() {
      if (!this.formReady) {
        this.$swal({
          icon: "error",
          text: "Please fill all required input",
        });
        return;
      }

      try {
        this.regging = true;

        const res = await this.$http.post("/auth/e/register", this.form, {
          headers: { noauth: true },
        });

        this.regging = false;
        if (!res) {
          return;
        }
        const { data } = res;
        this.$store.commit("general/saveUser", {
          user: data.data,
        });

        if (data.data.isActive && data.data.activationCode) {
          this.$swal({
            icon: "success",
            text: "This profile is active kindly Login or Change Password if Forgotten.",
          });
          this.$router.push({ name: "Login" });
          return;
        }

        if (!data.data.isActive && data.data.activationCode) {
          this.$swal({
            icon: "success",
            text: "This profile is Not active kindly Activate Your Account.",
          });
          this.$router.push({ name: "Account Verification" });
          return;
        }

        this.$swal({
          icon: "success",
          text: data.message,
        });
        this.$router.push({ name: "Account Verification" });
      } catch (error) {
        this.regging = false;
      }
    },
  },
};
</script>

<style scoped>
form {
  @apply mx-auto w-full mt-11 lg:mt-9;
  max-width: 35rem;
}
</style>
